import React, { useState, useEffect, useRef } from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

import HeaderLogo from "../../components/headerLogo/headerLogo"
import HeaderBack from "../../components/headerBack/headerBack"
import HeaderCart from "../../components/headerCart/headerCart"

import ProductV2 from "../../components/product/productV2"

function ProductShopify() {

    const shopifyProductId = '7104103383207'
    const base64ProductId = Buffer.from('gid://shopify/Product/' + shopifyProductId).toString('base64')

    const sizes = {
        "HALF CHEST WIDTH": [
            "",
            "14½/37",
            "15/38",
            "16/40,5",
            "16½/42,5",
            "17½/45",
            "",
            "",
            ""
        ],
        "LENGHT FROM CENTER BACK": [
            "",
            "13¾/35",
            "14/36",
            "15/38",
            "15/38,5",
            "15½/39,5",
            "",
            "",
            ""
        ],
        "SLEEVE LENGTH": [
            "",
            "22½/57",
            "22¾/58",
            "23½/60",
            "24/61",
            "24½/62",
            "",
            "",
            ""
        ],
        "SHOULDER WIDTH": [
            "",
            "14/36",
            "14½/37",
            "15/38",
            "15¼/39",
            "15¾/40",
            "",
            "",
            ""
        ]
    }


    return (
        <Layout>
            <SEO title="Shop - Carmine Top" />

            <ProductV2 productId={base64ProductId} sizes={sizes} />
        </Layout>
    )

} export default ProductShopify